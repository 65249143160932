import { User } from '@prisma/client';
import { prisma } from '../prisma';
import { TRPCError } from '@trpc/server';

export enum PermissionKey {
	// Menu Permissions for main menu,
	// and the job menu.
	MENU_DASHBOARD = 'MENU_DASHBOARD',
	MENU_ANALYTICS_DASHBOARD = 'MENU_ANALYTICS_DASHBOARD',
	MENU_JOBS = 'MENU_JOBS',
	MENU_QUICK_ESTIMATES = 'MENU_QUICK_ESTIMATES',
	MENU_INBOX = 'MENU_INBOX',
	MENU_CUSTOMERS = 'MENU_CUSTOMERS',
	MENU_TIME_TRACKING = 'MENU_TIME_TRACKING',
	MENU_SCHEDULING = 'MENU_SCHEDULING',
	MENU_PURCHASE_ORDERS = 'MENU_PURCHASE_ORDERS',
	MENU_SUPPLIERS_AND_MATERIALS = 'MENU_SUPPLIERS_AND_MATERIALS',
	MENU_TRANSACTIONS = 'MENU_TRANSACTIONS',
	MENU_REPORTING_JOBS = 'MENU_REPORTING_JOBS',
	MENU_REPORTING_ESTIMATES = 'MENU_REPORTING_ESTIMATES',
	MENU_REPORTING_PURCHASE_ORDERS = 'MENU_REPORTING_PURCHASE_ORDERS',
	MENU_REPORTING_AR_TRANSACTIONS = 'MENU_REPORTING_AR_TRANSACTIONS',
	MENU_TRAINING_VIDEOS = 'MENU_TRAINING_VIDEOS',
	MENU_SETTINGS = 'MENU_SETTINGS',

	// Job Menu specififc permissions
	JOB_MENU_OVERVIEW = 'JOB_MENU_OVERVIEW',
	JOB_MENU_ATTACHMENTS = 'JOB_MENU_ATTACHMENTS',
	JOB_MENU_ESTIMATES = 'JOB_MENU_ESTIMATES',
	JOB_MENU_CHANGE_ORDERS = 'JOB_MENU_CHANGE_ORDERS',
	JOB_MENU_GLASS_BUILDER = 'JOB_MENU_GLASS_BUILDER',
	JOB_MENU_WORK_ORDERS = 'JOB_MENU_WORK_ORDERS',
	JOB_MENU_MEASURES_AND_INSTALLS = 'JOB_MENU_MEASURES_AND_INSTALLS',
	JOB_MENU_DAILY_REPORTS = 'JOB_MENU_DAILY_REPORTS',
	JOB_MENU_QUOTE_REQUESTS = 'JOB_MENU_QUOTE_REQUESTS',
	JOB_MENU_PURCHASE_ORDERS = 'JOB_MENU_PURCHASE_ORDERS',
	JOB_MENU_PHASES = 'JOB_MENU_PHASES',
	JOB_MENU_KEY_DATES = 'JOB_MENU_KEY_DATES',
	JOB_MENU_PAYMENTS = 'JOB_MENU_PAYMENTS',
	JOB_MENU_JOB_COSTING = 'JOB_MENU_JOB_COSTING',
	JOB_MENU_COMMERCIAL_BILLING = 'JOB_MENU_COMMERCIAL_BILLING',
	JOB_MENU_FLOORPLANS = 'JOB_MENU_FLOORPLANS',

	// Action level permissions
	DELETE_JOB = 'DELETE_JOB',
	REASSIGN_JOB = 'REASSIGN_JOB',
	SET_PERMISSIONS = 'SET_PERMISSIONS',
}

export type UserWithPermissions = User & {
	userPermissions: {
		id: number;
		granted: boolean;
		permission: {
			id: number;
			name: string;
			key: PermissionKey | string;
		};
	}[];
	allowedPermissions: PermissionKey[];
};

export type UserWithPermissionsForGrid = {
	id: number;
	firstName: string;
	lastName: string;
	phone: string;
	permissions: {
		name: string;
		description: string | null;
		granted: boolean;
	}[];
};
