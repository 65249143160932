import { FAIcon } from '~/components/utils/FAIcons';

interface IEditButton {
	id: string;
	onClick: () => void;
	className?: string;
	buttonText?: string;
}

export const EditButton = ({ id, onClick, className = '', buttonText }: IEditButton) => {
	return (
		<button id={id} type="button" className={`ml-2 text-primary hover:text-primary-dark hover:cursor-pointer ${className}`} onClick={onClick}>
			<FAIcon icon="pen-to-square" />
			{buttonText && <span>{buttonText}</span>}
		</button>
	);
};
