import { useEffect } from 'react';
import { useUser } from '@clerk/nextjs';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { useUserContext } from '~/react_context/UserContext';

const RUMUser = () => {
	const { user } = useUser();
	const { businessName } = useUserContext();

	useEffect(() => {
		//console.log('Heres env variable:' + process.env.NEXT_PUBLIC_RUM_USER_ID_ENABLED);
		const rumEnabled = process.env.NEXT_PUBLIC_RUM_USER_ID_ENABLED ?? 'true';
		if (rumEnabled === 'true') {
			//console.log('Turning on RUM user data');
			datadogRum.setUser({
				id: user?.id,
				name: user?.fullName ?? undefined,
				email: user?.primaryEmailAddress?.emailAddress ?? undefined,
				businessName: businessName ?? undefined,
			});
		}
	}, [user]);

	useEffect(() => {
		datadogLogs.setGlobalContextProperty('clerkUserId', user?.id);
	}, [user]);

	return null;
};

export default RUMUser;
