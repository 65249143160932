import FAIcon from './FAIcon';
import { DuoToneFaIconProps } from './FAIconsProps';

const DuoToneFAIcon = ({ prefix, icon, className, sharp = false, DuoToneColor }: DuoToneFaIconProps) => {
	// TO DO: Add the logic to add the color to the icon

	// const duoToneStyle = CSSStyleDeclaration{
	//     --fa-primary-color: `${DuoToneColor.primaryColor}`,
	//     --fa-secondary-color: `${DuoToneColor.secondaryColor}`,
	// }

	return <FAIcon prefix={prefix} icon={icon} className={className} />;
};

export default DuoToneFAIcon;
