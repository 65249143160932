import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { AppType, NextWebVitalsMetric } from 'next/dist/shared/lib/utils';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import ErrorBoundary from '~/components/error-handling/ErrorBoundary';
import { Toaster } from '~/components/utils/Toaster';
import { DefaultLayout } from '~/layout/DefaultLayout';

import { trpc } from '@utils/trpc';

//to remove default margins and paddings
import '../../styles/globals.css';
import { FeatureFlag } from '@utils/featureFlags';
import { useRouter } from 'next/router';
import { PermissionKey } from '@schema/permission.schema';
import { Role, User } from '@prisma/client';
import { NavPermissionChecker } from '../components/navigation/navAuthChecker';
import { usePathname } from 'next/navigation';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
datadogLogs.init({
	clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
	site: process.env.NEXT_PUBLIC_DATADOG_SITE,
	forwardErrorsToLogs: true,
	sampleRate: 100,
	silentMultipleInit: true,
});
if (process.env.NEXT_PUBLIC_RUM_ENABLED === 'true') {
	datadogRum.init({
		applicationId: process.env.NEXT_PUBLIC_RUM_DATADOG_APPLICATION_ID!,
		clientToken: process.env.NEXT_PUBLIC_RUM_DATADOG_CLIENT_TOKEN!,
		site: process.env.NEXT_PUBLIC_DATADOG_SITE!,
		service: process.env.NEXT_PUBLIC_RUM_DATADOG_SERVICE!,

		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sampleRate: 100,
		sessionReplaySampleRate: 100,
		trackInteractions: true,
		trackFrustrations: true,
		trackResources: true,
		trackLongTasks: true,
		trackUserInteractions: true,
		defaultPrivacyLevel: 'allow',
	});

	datadogRum.startSessionReplayRecording();
}
/* eslint-enable @typescript-eslint/no-non-null-assertion */

export function reportWebVitals(metric: NextWebVitalsMetric) {
	datadogLogs.logger.info('next web vitals', metric);
}

export type NextPageWithLayout<TProps = Record<string, unknown>, TInitialProps = TProps> = NextPage<TProps, TInitialProps> & {
	getLayout?: (page: ReactElement, props: TProps) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

type UserWithPermissionKeys = {
	allowedPermissions: PermissionKey[];
	id: number;
	role: Role;
	firstName: string;
	lastName: string;
	userPermission: {
		permission: {
			key: string;
		};
	}[];
};

const GlazierApp = (({ Component, pageProps }: AppPropsWithLayout) => {
	const getLayout = Component.getLayout ?? ((page) => <DefaultLayout featureFlags={[] as FeatureFlag[]}>{page}</DefaultLayout>);

	const [authChecked, setAuthChecked] = useState(false);
	const pathname = usePathname();
	const [user, setUser] = useState<UserWithPermissionKeys | null>(null);
	const [permissions, setPermissions] = useState<PermissionKey[]>([]);
	const router = useRouter();
	// Get the navigation configuration

	useEffect(() => {
		if (
			pathname === '/404' ||
			pathname === '/500' ||
			pathname === '/sign-in' ||
			pathname === '/login' ||
			pathname === '/signed-out' ||
			pathname === '/403' ||
			// don't need to check auth on customer facing pages.
			pathname.indexOf('/customer-pages') > -1
		) {
			setAuthChecked(true);
			return;
		}
		// Call the authorization API to fetch user data
		const fetchUserPermissions = async () => {
			try {
				const res = await fetch('/api/auth', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				});

				if (res.status === 401) {
					// If unauthorized, redirect to login
					router.push('/sign-in');
					return;
				}

				const data = (await res.json()) as UserWithPermissionKeys;
				setUser(data);
				setPermissions(data.allowedPermissions);

				// Create the permission checker
				const permissionChecker = new NavPermissionChecker(data.allowedPermissions);

				const authorizationResult = permissionChecker.isAuthorized(pathname);
				if (!authorizationResult.authorized) {
					// If unauthorized, redirect to 403 with some nice details on permission check failures.
					router.push(
						`/403?userId=${data.id}&userName=${data.firstName} ${data.lastName}& path=${authorizationResult.path}&permissionKey=${authorizationResult.permissionKey}&fullUrl=${router.asPath}`
					);
					return;
				}

				setAuthChecked(true);
			} catch (error) {
				console.error('Error fetching user permissions:', error);
			}
		};

		fetchUserPermissions();
	}, [pathname, router]);
	// If authentication hasn't been checked yet, you can return a loading state or null
	if (!authChecked) {
		return <div>Auth Check...</div>;
	}

	return getLayout(
		<ErrorBoundary>
			<Component {...pageProps} user={user} permissions={permissions} />
			<Toaster />
		</ErrorBoundary>,
		pageProps
	);
}) as AppType;

export default trpc.withTRPC(GlazierApp);
