import { ifOverflowMatches } from 'recharts/types/util/IfOverflowMatches';
import { PermissionKey } from '@schema/permission.schema';
import { match } from 'path-to-regexp';
import { Role } from '@prisma/client';

interface NavItem {
	routingPath: string;
	permissionKey: PermissionKey;
}

export class NavPermissionChecker {
	private permissions: PermissionKey[];
	private navItems: NavItem[];

	constructor(permissions: PermissionKey[]) {
		this.permissions = permissions;
		this.navItems = NavItems();
	}

	// Check if a path is authorized based
	// on the permission of the user, and the path passed in.
	// will handle dynamic paths.
	public isAuthorized(path: string): {
		authorized: boolean;
		path: string;
		permissionKey: PermissionKey | null;
	} {
		path = path.toLowerCase();

		switch (path) {
			case '/404':
			case '/login':
			case '/signed-out':
			case '/403':
				return {
					authorized: true,
					path,
					permissionKey: null,
				};
			case '/':
				return {
					authorized: this.permissions.includes(PermissionKey.MENU_DASHBOARD),
					path,
					permissionKey: PermissionKey.MENU_DASHBOARD,
				};
			case '/jobs':
				return {
					authorized: this.permissions.includes(PermissionKey.MENU_JOBS),
					path,
					permissionKey: PermissionKey.MENU_JOBS,
				};
			case '/schedule':
				return {
					authorized: this.permissions.includes(PermissionKey.MENU_SCHEDULING),
					path,
					permissionKey: PermissionKey.MENU_SCHEDULING,
				};
			default:
				for (const navItem of this.navItems) {
					if (navItem.routingPath.indexOf(':') > -1) {
						const matcher = match(navItem.routingPath, { decode: decodeURIComponent });
						// Check if the path matches
						const result = matcher(path);
						if (result) {
							return {
								authorized: this.permissions.includes(navItem.permissionKey),
								path,
								permissionKey: navItem.permissionKey,
							};
						}
					}
					// This means this is a path that we're going to check pemission for
					if (navItem.routingPath === path) {
						// so we have a path that matching our
						return {
							authorized: this.permissions.includes(navItem.permissionKey),
							path,
							permissionKey: navItem.permissionKey,
						};
					}
				}
		}
		// We didn't find a protected route, so we're defaulting
		// to allow access
		return {
			authorized: true,
			path,
			permissionKey: null,
		}; // No matching path or permission found
	}
}

export const NavItems = () => {
	return [
		{
			routingPath: '/',
			permissionKey: PermissionKey.MENU_DASHBOARD,
		},
		{
			routingPath: '/analytics',
			permissionKey: PermissionKey.MENU_ANALYTICS_DASHBOARD,
		},
		{
			routingPath: '/jobs',
			permissionKey: PermissionKey.MENU_JOBS,
		},
		{
			routingPath: '/quick-estimate',
			permissionKey: PermissionKey.MENU_QUICK_ESTIMATES,
		},
		{
			routingPath: '/tasks',
			permissionKey: PermissionKey.MENU_INBOX,
		},
		{ routingPath: '/customers', permissionKey: PermissionKey.MENU_CUSTOMERS },
		{
			routingPath: '/time-tracking',
			permissionKey: PermissionKey.MENU_TIME_TRACKING,
		},
		{
			routingPath: '/schedule?view=Daily',
			permissionKey: PermissionKey.MENU_SCHEDULING,
		},
		{
			routingPath: '/purchase-orders',
			permissionKey: PermissionKey.MENU_PURCHASE_ORDERS,
		},
		{
			routingPath: '/materials',
			permissionKey: PermissionKey.MENU_SUPPLIERS_AND_MATERIALS,
		},
		{
			routingPath: '/transactions',
			permissionKey: PermissionKey.MENU_TRANSACTIONS,
		},
		{
			routingPath: '/reporting/jobs',
			permissionKey: PermissionKey.MENU_REPORTING_JOBS,
		},
		{
			routingPath: '/reporting/estimates',
			permissionKey: PermissionKey.MENU_REPORTING_ESTIMATES,
		},
		{
			routingPath: '/reporting/purchaseOrders',
			permissionKey: PermissionKey.MENU_REPORTING_PURCHASE_ORDERS,
		},
		{
			routingPath: '/reporting/transactions',
			permissionKey: PermissionKey.MENU_REPORTING_AR_TRANSACTIONS,
		},

		// Here's all the jobs specifc  urls
		{
			routingPath: `/jobs/:jobId`,
			permissionKey: PermissionKey.JOB_MENU_OVERVIEW,
		},
		{
			routingPath: `/jobs/:jobId/attachments`,
			permissionKey: PermissionKey.JOB_MENU_ATTACHMENTS,
		},
		{
			routingPath: `/jobs/:jobId/estimates/:estimateId`,
			permissionKey: PermissionKey.JOB_MENU_ESTIMATES,
		},
		{
			routingPath: `/jobs/:jobId/change-orders`,
			permissionKey: PermissionKey.JOB_MENU_CHANGE_ORDERS,
		},
		{
			routingPath: `/jobs/:jobId/glass-builder`,
			permissionKey: PermissionKey.JOB_MENU_GLASS_BUILDER,
		},
		{
			routingPath: `/jobs/:jobId/work-orders`,
			permissionKey: PermissionKey.JOB_MENU_WORK_ORDERS,
		},
		{
			routingPath: `/jobs/:jobId/site-photos`,
			permissionKey: PermissionKey.JOB_MENU_MEASURES_AND_INSTALLS,
		},
		{
			routingPath: `/jobs/:jobId/daily-reports`,
			permissionKey: PermissionKey.JOB_MENU_DAILY_REPORTS,
		},
		{
			routingPath: `/jobs/:jobId/quote-requests`,
			permissionKey: PermissionKey.JOB_MENU_QUOTE_REQUESTS,
		},
		{
			routingPath: `/jobs/:jobId/purchase-orders`,
			permissionKey: PermissionKey.JOB_MENU_PURCHASE_ORDERS,
		},
		{
			routingPath: `/jobs/:jobId/phases`,
			permissionKey: PermissionKey.JOB_MENU_PHASES,
		},
		{
			routingPath: `/jobs/:jobId/key-dates/edit`,
			permissionKey: PermissionKey.JOB_MENU_KEY_DATES,
		},
		{
			routingPath: `/jobs/:jobId/payments`,
			permissionKey: PermissionKey.JOB_MENU_PAYMENTS,
		},
		{
			routingPath: `/jobs/:jobId/job-costing`,
			permissionKey: PermissionKey.JOB_MENU_JOB_COSTING,
		},
		{
			routingPath: `/jobs/:jobId/commercial-billing`,
			permissionKey: PermissionKey.JOB_MENU_COMMERCIAL_BILLING,
		},
		{
			routingPath: `/jobs/:jobId/floor-plan`,
			permissionKey: PermissionKey.JOB_MENU_FLOORPLANS,
		},
		// Same permission, but for the billing period.
		{
			routingPath: `/jobs/:jobId/commercial-billing/billing-period/:billingPeriodId`,
			permissionKey: PermissionKey.JOB_MENU_COMMERCIAL_BILLING,
		},
		{
			routingPath: `/training-videos`,
			permissionKey: PermissionKey.MENU_TRAINING_VIDEOS,
		},
		// Notice the :settings-type in the routingPath.  This will protect
		// other routes in settings as well with the single perm
		// so /settinhgs/permissions will also be protected.
		{ routingPath: '/settings/:settingsType', permissionKey: PermissionKey.MENU_SETTINGS },
	];
};
