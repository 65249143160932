import React from 'react';
import Button from './Button';
import { SubmitButtonProps } from '../UtilityProps';

const SubmitButton = ({ children, className, form, reset = false, custom }: SubmitButtonProps) => {
	if (reset) {
		if (custom) {
			return (
				<Button form={form} type="reset" value="Reset" className={className} custom>
					{children}
				</Button>
			);
		} else {
			return (
				<Button form={form} type="reset" value="Reset" className={className}>
					{children}
				</Button>
			);
		}
	} else {
		if (custom) {
			return (
				<Button form={form} type="submit" className={className} custom>
					{children}
				</Button>
			);
		} else {
			return (
				<Button form={form} type="submit" className={className}>
					{children}
				</Button>
			);
		}
	}
};

export default SubmitButton;
