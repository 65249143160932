import Link from 'next/link';
import React from 'react';
import { ButtonProps } from '../UtilityProps';
import { classNames } from '~/components/utils';
import { useApproval } from '~/hooks/useApproval';

type ButtonStylesArgs = {
	className?: string;
	custom?: boolean;
	hasBorder?: boolean;
	hasPointer?: boolean;
	horizontalPad?: boolean;
	verticalPad?: boolean;
};

export const getButtonStyles = ({
	className = undefined,
	custom = false,
	hasBorder = true,
	hasPointer = false,
	horizontalPad = true,
	verticalPad = true,
}: ButtonStylesArgs = {}) => {
	return custom
		? classNames(className, 'inline-flex items-center')
		: classNames(
				className ? className : 'text-white hover:bg-primary-dark bg-primary',
				hasBorder ? 'border-2' : 'border border-transparent',
				hasPointer ? 'cursor-pointer' : '',
				horizontalPad ? 'px-4' : 'px-0',
				verticalPad ? 'py-2' : 'py-0',
				'inline-flex items-center rounded-md text-sm font-medium'
			);
};
export default function Button({
	id,
	children,
	className,
	link,
	form,
	externalLink,
	isExternalLinkInNewTab,
	type = 'button',
	custom,
	value,
	onClick,
	shouldStopPropagation,
	ref,
	title,
	verticalPad = true,
	horizontalPad = true,
	hasBorder = false,
	disabled = false,
	useApproval: useApprovalCheck,
	askToDoMessage = 'Are you sure?',
	style = {},
}: ButtonProps) {
	const { askToDo } = useApproval({ defaultMessage: askToDoMessage });

	const handleClick = useApprovalCheck && onClick ? () => askToDo(onClick) : onClick;

	//Don't use next structured links externally
	if (externalLink && typeof link === 'string') {
		return (
			<a
				id={id}
				title={title}
				href={link}
				target={isExternalLinkInNewTab ? '_blank' : undefined}
				rel="noopener noreferrer"
				type="button"
				className={getButtonStyles({
					custom,
					hasBorder,
					hasPointer: true,
					className,
					verticalPad,
					horizontalPad,
				})}
				style={style}
				onClick={(event) => {
					if (shouldStopPropagation) event.stopPropagation();
					if (handleClick) handleClick();
				}}
			>
				{children}
			</a>
		);
	} else {
		if (type == 'reset' || type == 'submit') {
			return (
				<button
					id={id}
					title={title}
					type={type}
					value={value}
					disabled={disabled}
					form={form}
					className={getButtonStyles({
						custom,
						hasBorder,
						className,
						verticalPad,
						horizontalPad,
					})}
					onClick={(event) => {
						if (shouldStopPropagation) event.stopPropagation();
						if (handleClick) handleClick();
					}}
					style={style}
				>
					{children}
				</button>
			);
		} else {
			if (link) {
				return (
					<Link href={link} legacyBehavior>
						<button
							id={id}
							title={title}
							type={type}
							value={value}
							disabled={disabled}
							className={getButtonStyles({
								custom,
								hasBorder,
								className,
								verticalPad,
								horizontalPad,
							})}
							style={style}
							onClick={(event) => {
								if (shouldStopPropagation) event.stopPropagation();
								if (handleClick) handleClick();
							}}
						>
							{children}
						</button>
					</Link>
				);
			} else {
				return (
					<button
						id={id}
						title={title}
						type="button"
						value={value}
						disabled={disabled}
						ref={ref}
						className={getButtonStyles({
							custom,
							hasBorder,
							className,
							verticalPad,
							horizontalPad,
						})}
						style={style}
						onClick={(event) => {
							if (shouldStopPropagation) event.stopPropagation();
							if (handleClick) handleClick();
						}}
					>
						{children}
					</button>
				);
			}
		}
	}
}
