import { MainNavProps } from '~/components/navigation/NavPropTypes';
import { NavUserButton } from '~/components/navigation/NavUserButton';
import { SidebarLogo } from './SidebarLogo';
import { VerticalNav } from './VerticalNav';

export const DrawerSidebar = ({ navItems, pathname }: MainNavProps) => {
	return (
		<div className="flex-1 flex flex-col h-0 pt-5 overflow-y-auto">
			<SidebarLogo />
			<VerticalNav navItems={navItems} pathname={pathname} closable={true} collapsible={false} />
			<NavUserButton />
		</div>
	);
};
