import { BusinessCategory, JobLogType } from '@prisma/client';
import { JobLogClientEntry } from '~/server/schema/jobLog.schema';

export const getJobLogIcon = (log: JobLogClientEntry) => {
	let icon = 'circle-info';
	switch (log.logType) {
		case JobLogType.STAGE_CHANGE:
			icon = 'bolt';
			break;
		case JobLogType.ASSIGNED:
			icon = 'user-check';
			break;
		case JobLogType.COMMENT_ADDED:
			icon = 'comment';
			break;
		case JobLogType.DOCUMENT_ATTACHED:
			icon = 'file';
			break;
		case JobLogType.ESTIMATE_GENERATED:
			icon = 'file-invoice-dollar';
			break;
		case JobLogType.WORK_ORDER_CREATED:
			icon = 'user-helmet-safety';
			break;
		case JobLogType.ESTIMATE_SENT:
			icon = 'paper-plane';
			break;
		case JobLogType.MEASUREMENT_CREATED:
			icon = 'ruler-combined';
			break;
		case JobLogType.TASK_CREATED:
			icon = 'tasks';
			break;
		case JobLogType.REMINDER_SENT:
			icon = 'paper-plane';
			break;
		case JobLogType.LOG:
			icon = 'circle-bookmark';
			break;
		case JobLogType.JOB_SCHEDULED:
			icon = 'calendar';
			break;
		case JobLogType.DEPOSIT_PAID:
			icon = 'sack-dollar';
			break;
		case JobLogType.PO_CREATED:
			icon = 'boxes-stacked';
			break;
		case JobLogType.PO_SENT:
			icon = 'parachute-box';
			break;
		case JobLogType.PO_CONFIRMED:
			icon = 'box-circle-check';
			break;
		case JobLogType.SCHEDULED_JOB_DELETED:
			icon = 'calendar-xmark';
			break;
		case JobLogType.FINAL_INVOICE_SENT:
			icon = 'paper-plane';
			break;
		case JobLogType.FINAL_INVOICE_PAID:
			icon = 'face-tongue-money';
			break;
		case JobLogType.ACCOUNTING_COMPLETE:
			icon = 'cash-register';
			break;
		case JobLogType.FABRICATION_COMPLETE:
			icon = 'hammer';
			break;
		case JobLogType.INSTALLATION_COMPLETE:
			icon = 'screwdriver-wrench';
			break;
		case JobLogType.ON_THE_WAY_SMS_SENT:
			icon = 'message-lines';
			break;
	}
	return icon;
};

export const getJobLogInfo = (log?: JobLogType) => {
	let colors = 'bg-glazier-blue-100 text-primary-dark';
	let icon = 'circle-info';
	switch (log) {
		case JobLogType.STAGE_CHANGE:
			colors = 'bg-cyan-200 text-cyan-800';
			icon = 'bolt';
			break;
		case JobLogType.ASSIGNED:
			colors = 'bg-glazier-blue-100 text-primary-dark';
			icon = 'user-check';
			break;
		case JobLogType.COMMENT_ADDED:
			colors = 'bg-indigo-200 text-indigo-800';
			icon = 'comment';
			break;
		case JobLogType.DOCUMENT_ATTACHED:
			colors = 'bg-yellow-200 text-yellow-800';
			icon = 'file';
			break;
		case JobLogType.ESTIMATE_GENERATED:
			colors = 'bg-green-300 text-green-800';
			icon = 'file-invoice-dollar';
			break;
		case JobLogType.WORK_ORDER_CREATED:
			colors = 'bg-orange-200 text-orange-800';
			icon = 'user-helmet-safety';
			break;
		case JobLogType.ESTIMATE_SENT:
			colors = 'bg-green-300 text-green-800';
			icon = 'paper-plane';
			break;
		case JobLogType.MEASUREMENT_CREATED:
			colors = 'bg-orange-200 text-orange-800';
			icon = 'ruler-combined';
			break;
		case JobLogType.TASK_CREATED:
			colors = 'bg-green-200 text-green-900';
			icon = 'tasks';
			break;
		case JobLogType.REMINDER_SENT:
			colors = 'bg-blue-200 text-blue-800';
			icon = 'paper-plane';
			break;
		case JobLogType.LOG:
			colors = 'bg-primary-dark text-white';
			icon = 'circle-bookmark';
			break;
		case JobLogType.JOB_SCHEDULED:
			colors = 'bg-cyan-200 text-cyan-800';
			icon = 'calendar';
			break;
		case JobLogType.DEPOSIT_PAID:
			colors = 'bg-green-300 text-green-800';
			icon = 'sack-dollar';
			break;
		case JobLogType.PO_CREATED:
			colors = 'bg-purple-200 text-purple-800';
			icon = 'boxes-stacked';
			break;
		case JobLogType.PO_SENT:
			colors = 'bg-purple-200 text-purple-800';
			icon = 'parachute-box';
			break;
		case JobLogType.PO_CONFIRMED:
			colors = 'bg-purple-200 text-purple-800';
			icon = 'box-circle-check';
			break;
		case JobLogType.SCHEDULED_JOB_DELETED:
			colors = 'bg-cyan-200 text-cyan-800';
			icon = 'calendar-xmark';
			break;
		case JobLogType.FINAL_INVOICE_SENT:
			colors = 'bg-green-300 text-green-800';
			icon = 'paper-plane';
			break;
		case JobLogType.FINAL_INVOICE_PAID:
			colors = 'bg-green-300 text-green-800';
			icon = 'face-tongue-money';
			break;
		case JobLogType.ACCOUNTING_COMPLETE:
			colors = 'bg-green-300 text-green-800';
			icon = 'cash-register';
			break;
		case JobLogType.FABRICATION_COMPLETE:
			colors = 'bg-orange-200 text-orange-800';
			icon = 'hammer';
			break;
		case JobLogType.INSTALLATION_COMPLETE:
			colors = 'bg-orange-200 text-orange-800';
			icon = 'screwdriver-wrench';
			break;
		default:
			colors = 'bg-orange-200 text-orange-800';
			icon = 'user-helmet-safety';
			break;
	}
	return { colors, icon };
};

export const getJobBusinessCategoryIcon = (category: BusinessCategory | 'QUICK_ESTIMATES') => {
	switch (category) {
		case BusinessCategory.COMMERCIAL:
			return { icon: 'building', rawColor: 'rgb(79 70 229)', color: 'text-indigo-600' }; //indigo-600
		case BusinessCategory.RESIDENTIAL:
			return { icon: 'home', rawColor: 'rgb(13 148 136)', color: 'text-teal-600' }; //teal-600
		case BusinessCategory.PICKUP:
			return { icon: 'truck-ramp-box', rawColor: 'rgb(22 163 74)', color: 'text-green-600' }; //green-600
		case BusinessCategory.SERVICE_WORK:
			return { icon: 'toolbox', rawColor: 'rgb(234 88 12)', color: 'text-orange-600' }; //orange-600
		case BusinessCategory.WINDOWS_AND_DOORS:
			return { icon: 'door-open', rawColor: 'rgb(234 88 12)', color: 'text-blue-600' }; //blue-600
		case BusinessCategory.MISCELLANEOUS:
			return { icon: 'question', rawColor: 'rgb(234 88 12)', color: 'text-red-600' }; //red-600
		case BusinessCategory.AUTO_GLASS:
			return { icon: 'car', rawColor: 'rgb(234 88 12)', color: 'text-red-600' }; //red-600
		case 'QUICK_ESTIMATES':
			return { icon: 'file-pen', rawColor: '#0BA3D5', color: 'text-primary' };
		default:
			return { icon: 'briefcase', rawColor: '#0BA3D5', color: 'text-primary' }; //primary
	}
};
