import { ReactNode } from 'react';
import FAIcon from '~/components/utils/FAIcons/FAIcon';

interface IRequestButton {
	id?: string;
	type?: 'submit' | 'button' | 'reset' | undefined;
	className?: string;
	disabled?: boolean;
	isLoading?: boolean;
	isSuccess?: boolean;
	disableOnSuccess?: boolean;
	showLoadingText?: boolean;
	successText?: string;
	children?: ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	form?: string;
	title?: string;
}

export const RequestButton = ({
	id,
	type = 'submit',
	className = 'bg-black px-5 py-2 rounded-md text-white text-lg font-medium',
	disabled = false,
	isLoading = false,
	isSuccess = false,
	disableOnSuccess = false,
	showLoadingText = true,
	successText = 'Success!',
	children = 'Submit',
	onClick,
	form,
	title,
}: IRequestButton) => {
	function shouldThisBeDisabled() {
		if (disableOnSuccess && !isLoading) {
			return isSuccess;
		} else {
			return isLoading ? isLoading : disabled;
		}
	}

	return (
		<button
			data-testid={id}
			id={id}
			form={form}
			type={type}
			className={className}
			disabled={shouldThisBeDisabled()}
			onClick={onClick}
			title={title}
		>
			{isLoading ? (
				<div className="flex items-center px-2">
					<FAIcon icon="spinner fa-spin" className={showLoadingText ? 'text-md mr-2 -ml-2' : 'text-md ml-1'} />
					{showLoadingText && <span>Loading</span>}
				</div>
			) : disableOnSuccess && isSuccess ? (
				successText
			) : (
				children
			)}
		</button>
	);
};
