/**
 * Hook to use an approval workflow
 *
 * @param defaultMessage string Popup message. Can be overridden when called.
 *
 * @returns
 * @member askToDo	(fn) => void	Execute fn if the user confirms.
 */
export const useApproval = ({ defaultMessage = 'OK continue, cancel to stop.' }: { defaultMessage?: string }) => {
	const askToDo = (todo: () => void, message?: string) => {
		const askResult = window.confirm(message ?? defaultMessage);
		if (askResult) {
			todo();
		}
	};

	return {
		askToDo,
	};
};
