import { create } from 'zustand';

interface NavState {
	collapsedNav: boolean;
	setCollapsedNav: (value: boolean) => void;
	sidebarOpen: boolean;
	setSidebarOpen: (value: boolean) => void;
}

export const useNavStore = create<NavState>((set) => ({
	collapsedNav: false,
	setCollapsedNav: (value) => set({ collapsedNav: value }),
	sidebarOpen: false,
	setSidebarOpen: (value) => set({ sidebarOpen: value }),
}));
