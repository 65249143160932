import { OutlinedButtonProps } from '../UtilityProps';
import Button from './Button';

export default function OutlinedButton({
	children,
	className,
	link,
	form,
	externalLink,
	type = 'button',
	value,
	onClick,
	ref,
	active,
}: OutlinedButtonProps) {
	return (
		<Button
			className={className ? className : `border-2 px-3 py-1.5 rounded-lg font-medium text-secondary hover:bg-gray-100 ${active ? active : ''}`}
			link={link}
			form={form}
			externalLink={externalLink}
			type={type}
			value={value}
			onClick={onClick}
			ref={ref}
			custom
		>
			{children}
		</Button>
	);
}
