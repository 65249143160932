import clsx from 'clsx';
import Image from 'next/legacy/image';
import Link from 'next/link';
import GlazierWhiteLockup from '~/../public/img/logos/glazier-lockup-white.svg';
import GlazierWhiteLogo from '~/../public/img/logos/glazier-logo-st-white.svg';
import { useNavStore } from '~/stores/nav.store';

export const SidebarLogo = () => {
	const { collapsedNav: collapsed } = useNavStore();

	return (
		<div className="w-full flex items-center justify-between px-4">
			<Link href="/" legacyBehavior>
				{/* Weird workaround for Link with nested functional components. Can see workaround from here: https://stackoverflow.com/questions/71214244/nextjs-link-component-wrapping-image-causes-errors-when-mapping-over-array-unles -WB */}
				<div
					className={clsx('duration-300 transition-all hover:cursor-pointer flex justify-center items-center', {
						'w-[50px]': collapsed,
						'w-[150px]': !collapsed,
					})}
				>
					<Image
						src={collapsed ? GlazierWhiteLogo : GlazierWhiteLockup}
						alt="Glazier Logo"
						width={collapsed ? 50 : 150}
						height={50}
						className="w-full cursor-pointer"
					/>
				</div>
			</Link>
		</div>
	);
};
