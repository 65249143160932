import { useUser } from '@clerk/nextjs';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useUserContext } from '~/react_context/UserContext';

export function Intercom() {
	const { user } = useUser();
	const { businessName, businessId } = useUserContext();

	const { boot } = useIntercom();

	useEffect(() => {
		boot({
			name: user?.fullName ?? undefined,
			email: user?.primaryEmailAddress?.emailAddress,
			userId: user?.id,
			phone: user?.primaryPhoneNumber?.phoneNumber,
			company: {
				name: businessName ?? undefined,
				companyId: String(businessId) ?? undefined,
			},
		});
	}, [user?.id]);

	return null;
}
