import React from 'react';
import { MutationButton } from './MutationButton';
import { FAIcon } from '../FAIcons';
import { IMutationButtonMutation } from './props';
import { classNames } from '..';

export interface MutationGroupButtonProps {
	id: string;
	mutation: IMutationButtonMutation;
	mutationOnClick?: () => void;
	cancelButtonFn: () => void;
	disableOnSuccess?: boolean;
	showLoadingText?: boolean;
	disabled?: boolean;
}

const MutationGroupButton = ({
	id,
	mutation,
	mutationOnClick,
	cancelButtonFn,
	disableOnSuccess = false,
	showLoadingText = true,
	disabled = false,
}: MutationGroupButtonProps) => {
	return (
		<div className="ml-2 inline-flex" id={id}>
			<MutationButton
				id={`job-owner-submit`}
				className={classNames(
					disabled ? 'text-gray-300 hover:bg-gray-300 hover:border-gray-300' : 'text-green-600 hover:bg-green-600 hover:border-green-600',
					'border border-r-0 rounded-l-lg h-7 w-7'
				)}
				disableOnSuccess={disableOnSuccess}
				mutation={mutation}
				onClick={mutationOnClick}
				showLoadingText={showLoadingText}
				disabled={disabled}
			>
				<FAIcon icon="check" />
			</MutationButton>
			<button
				type="button"
				onClick={cancelButtonFn}
				className={classNames(
					disabled ? 'text-gray-300 hover:bg-gray-300 hover:border-gray-300' : 'text-red-500 hover:bg-red-500 hover:border-red-500',
					'hover:text-white border border-l-0 h-7 w-7 rounded-r-lg'
				)}
			>
				<FAIcon icon="times" />
			</button>
		</div>
	);
};

export default MutationGroupButton;
