import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { classNames } from '../utils';

type Props = {
	children: ReactNode;
	scrollToBottomAction?: () => void;
	bgClass?: string;
};

export const PrimaryColumn = (props: Props) => {
	const mainCol = useRef<HTMLElement>(null);
	const isFetching = useRef(false);

	const fetchAtBottom = useCallback(() => {
		if (!props.scrollToBottomAction || !mainCol.current || isFetching.current) return;
		const { scrollTop, clientHeight, scrollHeight } = mainCol.current;
		if (scrollTop + clientHeight > scrollHeight - 1) {
			isFetching.current = true;
			props.scrollToBottomAction();
			isFetching.current = false;
		}
	}, [props]);

	const checkAndFetchAtBottom = useCallback(() => {
		if (!props.scrollToBottomAction || !mainCol.current || isFetching.current) return;

		const { clientHeight, scrollHeight } = mainCol.current;

		if (clientHeight >= scrollHeight) {
			isFetching.current = true;
			props.scrollToBottomAction();
			isFetching.current = false;
		}
	}, [props]);

	useEffect(() => {
		if (mainCol.current && props.scrollToBottomAction) {
			const _col = mainCol.current;

			_col.addEventListener('scroll', fetchAtBottom);
			checkAndFetchAtBottom();
			return () => {
				_col.removeEventListener('scroll', fetchAtBottom);
			};
		}
	}, [props.scrollToBottomAction, fetchAtBottom]);

	return (
		<main ref={mainCol} className={classNames('flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-first', props.bgClass)}>
			{/* Starts a first column with the above TW utility classes */}
			{props.children}
			{/* End content */}
		</main>
	);
};
