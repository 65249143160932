import { Address, ScheduledJobType } from '@prisma/client';

export const classNames = (...classes: (string | boolean | undefined | null)[]) => {
	return classes.filter((val) => !!val).join(' ');
};

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatAddress = ({ address, includeNewline = true }: { address: Address; includeNewline?: boolean }) => {
	const { street1, street2, city, state, zip } = address;
	return `${street1}, ${street2 ? `${street2}, ` : ''}${includeNewline ? `\n` : ``}${city}, ${state} ${zip}`;
};

export const capitalizeEveryWord = ({ text, delimiters = [' ', '-'] }: { text: string; delimiters?: string[] }) => {
	let tmpText = text.toLowerCase();

	delimiters.forEach((d) => {
		tmpText = tmpText.split(d).map(capitalizeFirstLetter).join(d);
	});

	return tmpText;
};

export function formatPhoneNumber(phoneNumberString: string | null | undefined) {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	const match = cleaned.match(/^\d?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	} else {
		return null;
	}
}

export function formatDollarAmount(amount: string | number) {
	let amountFormatted;

	if (typeof amount === 'string') {
		amountFormatted = parseFloat(amount);
	} else {
		amountFormatted = amount;
	}

	return '$' + amountFormatted.toFixed(2);
}

export function googleURLFormatter(address: Address, useCoords = false) {
	if (useCoords && address.latitude && address.longitude) {
		return `https://maps.google.com/?q=${address.latitude},${address.longitude}`;
	} else {
		//Throw it to google to do the right thing if we don't have a map location
		return `https://maps.google.com/?q=${formatAddress({ address })}`;
	}
}

export function formatStageName(stage: string) {
	return capitalizeEveryWord({ text: stage.split('_').join(' ') });
}

export function getFullName<T extends { firstName: string; lastName: string }>(user: T) {
	return `${user.firstName} ${user.lastName}`;
}

type IconFromSJType<T extends { jobType: ScheduledJobType }> = T;

export const iconFromScheduledJob = <T extends { jobType: ScheduledJobType }>(scheduledJob?: IconFromSJType<T>) => {
	if (!scheduledJob?.jobType) return 'calendar';
	switch (scheduledJob.jobType) {
		case ScheduledJobType.FABRICATION:
			return 'wrench';
		case ScheduledJobType.INSTALL:
			return 'window-frame';
		case ScheduledJobType.MEASURE:
			return 'ruler-combined';
		case ScheduledJobType.OTHER:
			return 'calendar-pen';
	}
};
