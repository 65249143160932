import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export const ErrorDialog = (props: { dialogOpen: boolean; children: ReactNode; closeFn?: () => void }) => {
	return (
		<Transition.Root show={props.dialogOpen} as={Fragment}>
			{/* NOTE: For the dialog, have to add the empty function to onClose to disable closing the dialog on clickaway/esc key press */}
			<Dialog as="div" className="relative z-50" onClose={props.closeFn ? props.closeFn : () => {}}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-400 bg-opacity-60 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center sm:items-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full w-full sm:max-w-lg">
								{props.children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
