// NOTE On ReactQuery, the error is of type unknown, which props can't be set to.
// Might be able to mimic how errors are typed in `pages/api/trpc/[trpc].ts`.
// @ts-ignore
const ErrorToastBody = ({ error }) => {
	if (error.data && error.data.httpStatus) {
		return (
			<div className="w-[250px] md:w-[450px]">
				<h1 className="text-md font-bold">{`Error ${error.data.httpStatus}`}</h1>
				<h2 className="text-sm font-bold">{error.data.code}</h2>
				<p className="max-h-[275px] overflow-y-auto">{error.message}</p>

				<h3 className="font-bold mt-1">Error Path</h3>
				<p className="text-md break-all border border-black bg-gray-100 p-2">{error.data.path}</p>
				<h3 className="font-bold mt-1">Error Trace</h3>
				<p className="max-h-[275px] overflow-y-auto pl-1 break-all">{error.stack}</p>
			</div>
		);
	}
};

export default ErrorToastBody;
