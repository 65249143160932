import { Fragment } from 'react';
import { FAIcon } from './utils/FAIcons';
import { FAIconProps } from './utils/FAIcons/FAIconsProps';

interface LoadingScreenProps {
	icon?: string;
	iconPrefix?: FAIconProps['prefix'];
	header?: string;
	subheader?: string;
	srText?: string;
}

export const LoadingScreen = ({ icon, iconPrefix, header, subheader, srText }: LoadingScreenProps) => {
	return (
		<Fragment>
			<div className="w-full h-full flex flex-col animate-pulse space-y-4 justify-center items-center text-gray-500">
				<h1 className="text-center sr-only">{srText ? srText : 'Things are loading.'}</h1>
				<FAIcon prefix={iconPrefix ? iconPrefix : 'fad'} icon={icon ? icon : 'hourglass-half'} className="text-6xl" />
				<div className="flex flex-col justify-center items-center">
					<span className="text-2xl font-bold">{header ? header : 'Loading...'}</span>
					<span className="font-medium">{subheader ? subheader : "We're gathering your data."}</span>
				</div>
			</div>
		</Fragment>
	);
};
