import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { FAIcon } from '../utils/FAIcons';
import { FAIconProps } from '../utils/FAIcons/FAIconsProps';
import { titlifyEnum } from '@utils/stringHelpers';

export type ErrorCodeKey =
	| 'PARSE_ERROR'
	| 'BAD_REQUEST'
	| 'INTERNAL_SERVER_ERROR'
	| 'UNAUTHORIZED'
	| 'FORBIDDEN'
	| 'NOT_FOUND'
	| 'METHOD_NOT_SUPPORTED'
	| 'TIMEOUT'
	| 'CONFLICT'
	| 'PRECONDITION_FAILED'
	| 'PAYLOAD_TOO_LARGE'
	| 'CLIENT_CLOSED_REQUEST'
	| 'UNPROCESSABLE_CONTENT'
	| 'TOO_MANY_REQUESTS';

interface IErrorScreenProps {
	status?: ErrorCodeKey;
	redirectOnUnauthorized?: boolean;
	icon?: string;
	iconPrefix?: FAIconProps['prefix'];
	srText?: string;
	message?: string;
}

export const ErrorScreen = ({ status, redirectOnUnauthorized, icon, iconPrefix, srText, message }: IErrorScreenProps) => {
	const router = useRouter();

	useEffect(() => {
		if (redirectOnUnauthorized && status === 'UNAUTHORIZED') {
			router.push('/');
		}
	}, []);

	const ErrorBody = () => {
		switch (status) {
			case 'UNAUTHORIZED':
				return (
					<>
						<h2 className="text-lg">You do not have access to this resource.</h2>
						<p className="text-lg">If you think this is a mistake, please contact your supervisor or Glazier tech support.</p>
						{message && <p className="text-lg">{message}</p>}
					</>
				);
			case 'NOT_FOUND':
				return (
					<>
						<h2 className="text-lg">Resource not found.</h2>
						<p className="text-lg">Could not find the resource at this location.</p>
						{message && <p className="text-lg">{message}</p>}
					</>
				);
			default:
				return <h2 className="text-lg">{message ? message : 'Oops, an error occured while loading this page!'}</h2>;
		}
	};

	const errorIconClassName = () => {
		switch (status) {
			case 'UNAUTHORIZED':
				return 'lock-keyhole';
			case 'NOT_FOUND':
				return 'block-question';
			default:
				return 'bug';
		}
	};

	return (
		<div className="w-full h-full flex flex-col space-y-4 justify-center items-center text-black">
			<h1 className="text-center sr-only">{srText ? srText : 'You do not have access to this resource.'}</h1>
			<FAIcon prefix={iconPrefix ? iconPrefix : 'fad'} icon={icon ? icon : errorIconClassName()} className="text-6xl text-primary" />
			<div className="flex flex-col justify-center items-center">
				<h1 className="text-2xl font-bold">{status ? titlifyEnum(status) : 'Error'}</h1>
				<ErrorBody />
			</div>
		</div>
	);
};
