import { UserButton } from '@clerk/nextjs';
import { useNavStore } from '~/stores/nav.store';
import { NavUserProps } from './NavPropTypes';

export const NavUserButton = ({ borderColor = 'border-glazier-navy-500' }: NavUserProps) => {
	const { collapsedNav } = useNavStore();

	return (
		<div className={`flex-shrink-0 flex border-t ${borderColor} p-4 ${collapsedNav ? 'justify-center' : null}`}>
			<div className="inline-flex">
				<UserButton showName={false} />
			</div>
		</div>
	);
};
