import { ReactNode } from 'react';

type Props = {
	children: ReactNode;
};

export const XLOnlyColumn = (props: Props) => {
	return (
		<aside className="hidden relative xl:order-last xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200 overflow-y-auto">
			{/* Start secondary column (hidden on smaller screens) */}
			{props.children}
			{/* End secondary column */}
		</aside>
	);
};
