import { ToastContainer } from 'react-toastify';
import { FAIcon } from './FAIcons';
import 'react-toastify/dist/ReactToastify.css';

interface IToastifyContextClass {
	success: string;
	error: string;
	info: string;
	warning: string;
	default: string;
	dark: string;
}

// @ts-ignore
const CloseButton = ({ closeToast }) => (
	<div className="absolute top-0 right-2" onClick={closeToast}>
		<FAIcon icon="times" className="text-black hover:text-gray-500" />
	</div>
);

export const Toaster = () => {
	const contextClass: IToastifyContextClass = {
		success: 'bg-white text-black border border-gray-300 shadow-xl font-medium',
		error: 'bg-pink-100 text-black border border-glazier-blue-700 border-1 shadow-xl',
		info: 'bg-white border border-primary text-primary',
		warning: 'bg-yellow-100 text-yellow-800',
		default: 'bg-white text-black border-gray-400 shadow-xl',
		dark: 'bg-white-600 font-gray-300',
	};

	return (
		<ToastContainer
			className="w-[320px] md:w-[500px]"
			// @ts-ignore
			toastClassName={({ type }) =>
				// @ts-ignore
				contextClass[type || 'default'] +
				' relative flex pt-2 pb-3 px-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mb-2 shadow-medium w-[320px] md:w-[500px]'
			}
			bodyClassName={() => 'text-lg flex items-start'}
			closeButton={CloseButton}
		/>
	);
};
