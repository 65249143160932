import { ReactNode } from 'react';
import { RequestButton } from '~/components/utils/buttons/RequestButton';
import { IMutationButtonMutation } from './props';
import { useApproval } from '~/hooks/useApproval';

interface IMutationButtonProps {
	id?: string;
	type?: 'submit' | 'button' | 'reset' | undefined;
	mutation?: IMutationButtonMutation;
	disabled?: boolean;
	disableOnSuccess?: boolean;
	successText?: string;
	text?: string;
	className?: string;
	children?: ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	throttleLength?: number;
	form?: string;
	showLoadingText?: boolean;
	useApproval?: boolean;
	askToDoMessage?: string;
	title?: string;
	enabled?: boolean;
}

export const MutationButton = ({
	id,
	className = 'bg-black px-5 py-2 rounded-md text-white text-lg font-medium',
	mutation,
	disabled = false,
	disableOnSuccess = false,
	type = 'submit',
	children = 'Submit',
	successText = 'Success!',
	onClick,
	throttleLength,
	form,
	showLoadingText = true,
	useApproval: useApprovalCheck,
	askToDoMessage = 'Are you sure?',
	title,
}: IMutationButtonProps) => {
	// NOTE Throttle length only affects button usability
	// if disableOnSuccess === false
	if (throttleLength) {
		if (mutation?.status === 'success') {
			setTimeout(mutation.reset, throttleLength);
		}
	}

	const { askToDo } = useApproval({ defaultMessage: askToDoMessage });

	const handleClick =
		useApprovalCheck && onClick
			? (event: React.MouseEvent<HTMLButtonElement>) => {
					event.preventDefault();
					askToDo(() => onClick(event));
				}
			: onClick;

	return (
		<>
			<RequestButton
				id={id}
				form={form}
				type={type}
				className={className}
				disabled={disabled}
				isLoading={mutation?.status === 'loading'}
				showLoadingText={showLoadingText}
				onClick={handleClick}
				isSuccess={mutation?.status === 'success'}
				disableOnSuccess={disableOnSuccess}
				successText={successText}
				title={title}
			>
				{children}
			</RequestButton>
		</>
	);
};
