import { JobPhase } from '@prisma/client';
import Link from 'next/link';
import clsx from 'clsx';
import { FAIcon } from '~/components/utils/FAIcons';
import Popover from '~/components/utils/Popover';
import { useNavStore } from '~/stores/nav.store';

export const SidebarCreateJobButton = () => {
	const { collapsedNav: isCollapsed } = useNavStore();

	return (
		<Popover>
			<Popover.Button
				closedClassName={clsx('bg-primary text-white flex justify-center w-full flex-grow transition-all duration-300 ease-in-out', {
					'w-10 h-10': isCollapsed,
					'w-full h-10': !isCollapsed,
				})}
				openClassName={clsx('bg-primary-dark text-white flex justify-center transition-all duration-300 ease-in-out', {
					'w-10 h-10': isCollapsed,
					'w-full h-10': !isCollapsed,
				})}
			>
				<FAIcon icon="plus" />
				<span
					className={clsx('whitespace-nowrap pl-2 transition-opacity duration-300 ease-in-out text-center', {
						hidden: isCollapsed,
						block: !isCollapsed,
					})}
				>
					Create New
				</span>
			</Popover.Button>
			<Popover.Panel xPosition={clsx('left-0 right-0 transition-all duration-300 ease-in-out', { 'w-40': isCollapsed })} zIndex="z-20">
				<div>
					<Link
						href={`/jobs/create/?type=${JobPhase.ESTIMATE}`}
						className="text-gray-700 hover:bg-gray-200 group flex items-center px-3 py-2"
					>
						<FAIcon icon="bullseye-arrow" className="mr-2 text-primary" />
						Bid
					</Link>
					<Link href={`/quick-estimate/create`} className="text-gray-700 hover:bg-gray-200 group flex items-center px-3 py-2">
						<FAIcon icon="bolt" className="mr-2 text-primary" />
						Quick Estimate
					</Link>
					<Link
						href={`/jobs/create/?type=${JobPhase.WORK_ORDER}`}
						className="text-gray-700 hover:bg-gray-200 group flex items-center px-3 py-2"
					>
						<FAIcon icon="hammer" className="mr-2 text-primary" />
						Won Job
					</Link>
				</div>
			</Popover.Panel>
		</Popover>
	);
};
