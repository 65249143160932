/**
 * Hook to handle the state changes of the new Popover componentz.
 *
 * @returns
 * @member isPopoverOpen	(boolean)	Whether the popover is open or not.
 * @member setIsPopoverOpen (React.Dispatch<React.SetStateAction<boolean>>) Setter for the isPopoverOpen state.
 * @member togglePopover	(fn) => void	Toggle the popover open or closed.
 *
 **/

import { useState } from 'react';

const usePopoverOpen = () => {
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

	const togglePopover = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};

	return {
		isPopoverOpen,
		setIsPopoverOpen,
		togglePopover,
	};
};

export default usePopoverOpen;
