import React, { Dispatch, ReactElement, SetStateAction, createContext, useContext, FC as FunctionalComponent, MouseEvent } from 'react';
import { useClickOutside } from '@mantine/hooks';
import { PopoverPanelProps as PanelProps, PopoverButtonProps as ButtonProps, PopoverProps } from './UtilityProps';
import { classNames } from '.';
import usePopoverOpen from '~/hooks/utils/popover/usePopoverOpen';
import { FAIcon } from './FAIcons';

type IPopoverContext = {
	isPopoverOpen: boolean;
	setIsPopoverOpen: Dispatch<SetStateAction<boolean>>;
	togglePopover: () => void;
};

const PopoverContext = createContext<IPopoverContext | null>(null);

const usePopoverContext = () => {
	const context = useContext(PopoverContext);
	if (!context) {
		throw new Error('usePopoverContext must be used within a PopoverContextProvider');
	}
	return context;
};

const PopoverContextProvider = ({ children }: PopoverProps) => {
	const { isPopoverOpen, setIsPopoverOpen, togglePopover } = usePopoverOpen();

	const contextValues = {
		isPopoverOpen,
		setIsPopoverOpen,
		togglePopover,
	};

	return <PopoverContext.Provider value={contextValues}>{children}</PopoverContext.Provider>;
};

const Panel: FunctionalComponent<PanelProps> = ({ children, className, xPosition, yPosition, zIndex }) => {
	const { isPopoverOpen } = usePopoverContext();

	return (
		<div
			className={classNames(
				isPopoverOpen ? 'block absolute' : 'hidden',
				className ? className : 'bg-white rounded-md shadow-lg mt-3 whitespace-nowrap  px-4 sm:px-0',
				xPosition ? xPosition : 'left-0',
				yPosition ? yPosition : 'top-[100%]',
				zIndex ? zIndex : 'z-10'
			)}
		>
			{children}
		</div>
	);
};

const Button: FunctionalComponent<ButtonProps> = ({
	children,
	icon,
	iconRotate = true,
	iconClassName,
	onClick,
	openClassName = 'bg-gray-200 hover:bg-gray-400',
	closedClassName = 'bg-white border-2 border-gray-200 hover:bg-gray-200',
	globalClassName = 'inline-flex items-center rounded-md px-4 py-2 text-sm font-medium',
	type = 'button',
}) => {
	const { isPopoverOpen, togglePopover } = usePopoverContext();

	const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		event.stopPropagation(); //stops parent click events from influencing the main component
		if (onClick) {
			onClick();
			togglePopover();
		} else {
			togglePopover();
		}
	};

	return (
		<button
			type={type}
			className={classNames(isPopoverOpen ? openClassName : closedClassName, globalClassName)}
			onClick={(event) => handleClick(event)}
		>
			{children}
			{icon && (
				<FAIcon
					icon={icon}
					className={classNames(isPopoverOpen && iconRotate ? 'rotate-180 transform' : '', iconClassName ? iconClassName : 'ml-2')}
				/>
			)}
		</button>
	);
};

const PopoverContainer = ({ children }) => {
	const { isPopoverOpen, togglePopover } = usePopoverContext();

	const ref = useClickOutside(() => {
		if (isPopoverOpen) {
			togglePopover();
		}
	});

	return <div ref={ref}>{children}</div>;
};

const Popover: FunctionalComponent<PopoverProps> & {
	Button: FunctionalComponent<ButtonProps>;
	Panel: FunctionalComponent<PanelProps>;
} = ({ children }) => {
	return (
		<PopoverContextProvider>
			<PopoverContainer>
				<div className="relative">
					{React.Children.map(children, (child) => {
						if (React.isValidElement(child)) {
							if (child.type === Button) {
								return React.cloneElement(child as ReactElement, {
									icon: child.props.icon,
									iconRotate: child.props.iconRotate,
									iconClassName: child.props.iconClassName,
									globalClassName: child.props.globalClassName,
									openClassName: child.props.openClassName,
									closedClassName: child.props.closedClassName,
									onClick: child.props.onClick,
									type: child.props.type,
								});
							}
							if (child.type === Panel) {
								return React.cloneElement(child as ReactElement, {
									className: child.props.className,
									xPosition: child.props.xPosition,
									yPosition: child.props.yPosition,
								});
							}
						}
						return child;
					})}
				</div>
			</PopoverContainer>
		</PopoverContextProvider>
	);
};

Popover.Button = ({ children, icon, iconRotate = true, iconClassName, onClick, openClassName, closedClassName, globalClassName, type }) => (
	<Button
		icon={icon}
		iconRotate={iconRotate}
		iconClassName={iconClassName}
		globalClassName={globalClassName}
		openClassName={openClassName}
		closedClassName={closedClassName}
		onClick={onClick}
		type={type}
	>
		{children}
	</Button>
);

Popover.Panel = ({ children, className, xPosition, yPosition, zIndex }) => (
	<Panel xPosition={xPosition} yPosition={yPosition} className={className} zIndex={zIndex}>
		{children}
	</Panel>
);

export default Popover;
