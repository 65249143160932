import React, { forwardRef } from 'react';
import { FAIconProps } from './FAIconsProps';

const FAIcon = forwardRef(
	({ prefix = 'far', icon, className, faClassName, sharp = false, alt, style }: FAIconProps, ref: React.ForwardedRef<HTMLSpanElement>) => {
		if (!sharp) {
			return (
				<span style={style} className={className} aria-label={alt} title={alt} ref={ref}>
					<i className={`${prefix} fa-${icon} ${faClassName ?? ''}`} />
				</span>
			);
		} else {
			return (
				<span style={style} className={className} aria-label={alt} title={alt} ref={ref}>
					<i className={`fa-sharp ${prefix} fa-${icon} ${faClassName ?? ''}`} />
				</span>
			);
		}
	}
);

export default FAIcon;
