import { JobPhase } from '@prisma/client';
import { titlifyEnum } from '@utils/stringHelpers';

export const getJobPhaseDisplayText = (jobPhase: JobPhase) => {
	switch (jobPhase) {
		case JobPhase.ESTIMATE:
			return 'Bid';
		case JobPhase.QUICK_ESTIMATE:
			return 'Bid';
		case JobPhase.WORK_ORDER:
			return 'Won Job';
		default:
			return titlifyEnum(jobPhase);
	}
};

interface PhaseIdBadgeProps {
	jobId: number;
	jobPhase: JobPhase;
	containerClass?: string;
	mtEnabled?: boolean;
}

const PhaseIdBadge = ({ jobId, jobPhase, containerClass = '', mtEnabled = true }: PhaseIdBadgeProps) => {
	let colors = 'bg-gray-100 text-gray-600 border-gray-500';
	let borderColor = 'border-gray-500';
	switch (jobPhase) {
		case JobPhase.ESTIMATE:
			colors = 'bg-cyan-100 text-cyan-900';
			borderColor = 'border-cyan-500';
			break;
		case JobPhase.JOB_LOST:
			colors = 'bg-red-200 text-red-900';
			borderColor = 'border-red-500';
			break;
		case JobPhase.JOB_COMPLETE:
			colors = 'bg-purple-200 text-purple-900';
			borderColor = 'border-purple-500';
			break;
		case JobPhase.WORK_ORDER:
			colors = 'bg-green-200 text-green-900';
			borderColor = 'border-green-500';
			break;
	}

	return (
		<div className={`${mtEnabled ? 'mt-1' : ''} flex items-center ${containerClass}`}>
			<div
				className={`whitespace-nowrap text-xs font-medium border ${borderColor} border-r-0 bg-gray-100 text-gray-600 h-full rounded-l-md py-0.5 px-1.5`}
			>
				{/* <FAIcon icon="fingerprint" className="mr-1"/> */}
				ID: {jobId}
			</div>
			<div
				className={`border ${borderColor} ${colors} inline-flex items-center justify-center rounded-r-full px-1.5 py-0.5 text-xs font-medium text-center whitespace-nowrap`}
			>
				{getJobPhaseDisplayText(jobPhase)}
			</div>
		</div>
	);
};

export default PhaseIdBadge;
