import { FeatureFlag, WithFeatureFlags } from '@utils/featureFlags';
import { ReactNode, createContext, useContext } from 'react';
import { useUserContext } from './UserContext';

interface FeatureFlagProviderProps extends WithFeatureFlags {
	children: ReactNode;
}

export const FeatureFlagContext = createContext<WithFeatureFlags | null>(null);

export function useFeatureFlagContext() {
	const context = useContext(FeatureFlagContext);
	if (context == null) throw new Error('Feature Flag Context is not ready.');
	return context;
}

export const FeatureFlagProvider = ({ children, featureFlags: featureFlagsFromProps = [] }: FeatureFlagProviderProps) => {
	const { featureFlags: featureFlagsFromBusiness } = useUserContext();
	const allFeatureFlags = new Set<FeatureFlag>([...featureFlagsFromBusiness, ...featureFlagsFromProps]);
	const value = { featureFlags: [...allFeatureFlags] };
	return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};
