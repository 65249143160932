import { Component, ErrorInfo, ReactNode } from 'react';
import { withRouter, NextRouter } from 'next/router';
import { ErrorDialog } from '~/components/error-handling/ErrorDialog';
import { datadogLogs } from '@datadog/browser-logs';

interface IProps {
	children: ReactNode;
	router: NextRouter;
}

interface IState {
	hasError: boolean;
	stackTrace?: string;
}

class ErrorBoundary extends Component<IProps, IState> {
	public state: IState = {
		hasError: false,
		stackTrace: '',
	};

	public static getDerivedStateFromError(_: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({ stackTrace: errorInfo.componentStack ?? undefined });
		console.error('Error: ', error, errorInfo);
		datadogLogs.logger.error(error.message, { error, errorInfo });
	}

	render() {
		const returnLink = this.props.router.asPath.includes('/field') ? '/field' : '/';

		if (this.state.hasError) {
			return (
				<ErrorDialog dialogOpen={true}>
					<h2 className="text-4xl font-medium py-3 px-4 bg-glazier-blue-800 rounded-t-md text-white">Error</h2>
					<div>
						<p className="py-3 px-4 font-medium text-3xl text-center">Whoops! An error occurred.</p>
						{/* If there is a stacktrace, display it in a scrollable box */}
						{this.state.stackTrace ? (
							<div className="max-h-[300px] overflow-y-auto px-2">
								<p className="">Error: {this.state.stackTrace}</p>
							</div>
						) : (
							''
						)}
					</div>
					<div className="w-full flex justify-center px-2 pt-2 pb-4 space-x-6">
						{/* NOTE: Commenting this out: would be nice to have a back button that can reload the previous route without an error,
                  // theoretically if we put the ErrorBoundary above the children within a Layout component that should work. 
                  <button type="button" className="black-button-xl" onClick={() => this.props.router.back()}>
							Back
						</button> */}
						<button type="button" className="black-button-xl" onClick={() => this.props.router.push(returnLink)}>
							Home
						</button>
					</div>
				</ErrorDialog>
			);
		}

		return this.props.children;
	}
}

export default withRouter(ErrorBoundary);
